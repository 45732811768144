import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { useAuth } from 'auth/contexts/auth.context';
import { getAuth, isSignInWithEmailLink } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { PrimaryButton } from 'shared/components/buttons';
import TextInput from 'shared/components/text-input/text-input';
import './social-buttons.scss';

const auth = getAuth();

export const Login = ({
  onClickGoogle,
  onClickFacebook,
  isVisible = true,
}: {
  isVisible?: boolean;
  onClickGoogle: () => void;
  onClickFacebook: () => void;
}) => {
  return (
    <div>
      <h1 className="font-bold sign-in-text">Sign in/up:</h1>
      <FacebookLoginButton tabIndex={isVisible ? 1 : -1} onClick={onClickFacebook} />
      <GoogleLoginButton tabIndex={isVisible ? 1 : -1} onClick={onClickGoogle} />
      <SignIn />
    </div>
  );
};

const FacebookLoginButton = ({ onClick, className, ...restProps }: any) => {
  return (
    <button
      onClick={onClick}
      className={`${!!className && className} facebook-button`}
      {...restProps}
      aria-label="login with facebook"
    >
      <div className="items-center flex h-full">
        <div className="flex justify-center min-w-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 90 90">
            <g>
              <path
                d="M90,15.001C90,7.119,82.884,0,75,0H15C7.116,0,0,7.119,0,15.001v59.998   C0,82.881,7.116,90,15.001,90H45V56H34V41h11v-5.844C45,25.077,52.568,16,61.875,16H74v15H61.875C60.548,31,59,32.611,59,35.024V41   h15v15H59v34h16c7.884,0,15-7.119,15-15.001V15.001z"
                fill="#FFFFFF"
              ></path>
            </g>
          </svg>
        </div>
        <div className="text-left w-full ml-2">Log in with Facebook</div>
      </div>
    </button>
  );
};

const EmailLoginButton = ({ onClick, className, ...restProps }: any) => {
  return (
    <button
      onClick={onClick}
      className={`${!!className && className} facebook-button`}
      {...restProps}
      aria-label="login with email"
    >
      <div className="items-center flex h-full">
        <div className="flex justify-center min-w-4">
          <EnvelopeIcon className="h-5 w-5" />
        </div>
        <div className="text-left w-full ml-2">Log in with Email</div>
      </div>
    </button>
  );
};

const GoogleLoginButton = ({ onClick, className, ...restProps }: any) => {
  return (
    <button
      onClick={onClick}
      className={`${!!className && className} google-button`}
      {...restProps}
      aria-label="login with google"
    >
      <div className="items-center flex h-full">
        <div className="justify-center flex minw-w-4">
          <svg
            width="26px"
            height="26px"
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
            viewBox="0 0 50 50"
          >
            <path d="M25.996 48C13.313 48 2.992 37.684 2.992 25S13.312 2 25.996 2a22.954 22.954 0 0 1 15.492 5.996l.774.707-7.586 7.586-.703-.602a12.277 12.277 0 0 0-7.977-2.957c-6.766 0-12.273 5.504-12.273 12.27s5.507 12.27 12.273 12.27c4.879 0 8.734-2.493 10.55-6.739h-11.55V20.176l22.55.031.169.793c1.176 5.582.234 13.793-4.531 19.668C39.238 45.531 33.457 48 25.996 48z"></path>
          </svg>
        </div>
        <div className="text-left w-full ml-2">Login with Google</div>
      </div>
    </button>
  );
};

export default function SignIn() {
  const { emailLoginLink, loginWithEmailLink } = useAuth();
  // Will try to use previously entered email, defaults to an empty string
  const [email, setEmail] = useState(window.localStorage.getItem('emailForSignIn') || '');
  const [errorResponse, setErrorResponse] = useState('');

  // When this component renders
  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let emailForSignIn = window.localStorage.getItem('emailForSignIn');
      if (!emailForSignIn) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        emailForSignIn = window.prompt('Please provide your email for confirmation');
      }
      loginWithEmailLink(emailForSignIn!);
    }
  }, []);

  const clearError = () => {
    if (errorResponse != '') {
      setErrorResponse('');
    }
  };

  const updateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError();
    setEmail(e.target.value);
  };

  const trySignIn = () => {
    emailLoginLink(email, `${window.location.protocol}//${window.location.host}/account`).catch(
      (error: { code: string; message: string }) => {
        setErrorResponse(error.message);
      }
    );
  };

  return (
    <div className="mt-6">
      <TextInput
        id="email"
        onChange={updateEmail}
        label="Or sign in by sending a login link to your email address"
        value={email}
        placeholder="name@mail.com"
      />
      <div>{errorResponse}</div>
      <div className="flex justify-end">
        <PrimaryButton onClick={trySignIn} className="mt-2">
          Send
        </PrimaryButton>
      </div>
    </div>
  );
}
