import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '../icons/arrow-left-icon';

export const TopBar = ({
  navigateBackUrl,
  title,
  children,
}: {
  navigateBackUrl: string;
  title: string;
  children?: React.ReactNode;
}) => {
  const navigate = useNavigate();
  return (
    <header className="h-12 bg-black text-white flex items-center px-2">
      <div className="flex-1">
        <ArrowLeftIcon
          size={24}
          className={'fill-current hover:text-primary-500 mr-5'}
          onClick={() => navigate(navigateBackUrl)}
        />
      </div>
      <div className="text-sm uppercase">{title}</div>
      <div className="flex-1 justify-end flex items-center">{children}</div>
    </header>
  );
};
