import { useState, useEffect } from 'react';
import {useShouldShowPwaPrompt} from "./use-should-show-pwa-prompt";
const webInstallPromptedAt = 'webInstallPromptedAt';

export const useWebInstallPrompt = (): [any, () => void, () => void] => {
    const [installPromptEvent, setInstallPromptEvent] = useState<BeforeInstallPromptEvent | null>(null);
    const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] = useShouldShowPwaPrompt(webInstallPromptedAt);

    useEffect(() => {
        const beforeInstallPromptHandler = (event: Event )=> {
            event.preventDefault();

            // check if user has already been asked
            if (userShouldBePromptedToInstall) {
                // store the event for later use
                setInstallPromptEvent(event as BeforeInstallPromptEvent);
            }
        };
        window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);
        return () => window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    }, [userShouldBePromptedToInstall]);

    const handleInstallDeclined = () => {
        handleUserSeeingInstallPrompt();
        setInstallPromptEvent(null);
    };

    const handleInstallAccepted = () => {
        if(!!installPromptEvent) {
            // show native prompt
            installPromptEvent.prompt();

            // decide what to do after the user chooses
            installPromptEvent.userChoice.then(choice => {
                // if the user declined, we don't want to show the prompt again
                if (choice.outcome !== 'accepted') {
                    handleUserSeeingInstallPrompt();
                }
                setInstallPromptEvent(null);
            });
        }
    };
    return [installPromptEvent, handleInstallDeclined, handleInstallAccepted];
};

/**
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a web site to a home screen on mobile.
 *
 * @deprecated Only supported on Chrome and Android Webview.
 */
interface BeforeInstallPromptEvent extends Event {

    /**
     * Returns an array of DOMString items containing the platforms on which the event was dispatched.
     * This is provided for user agents that want to present a choice of versions to the user such as,
     * for example, "web" or "play" which would allow the user to chose between a web version or
     * an Android version.
     */
    readonly platforms: Array<string>;

    /**
     * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
     */
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed',
        platform: string
    }>;

    /**
     * Allows a developer to show the install prompt at a time of their own choosing.
     * This method returns a Promise.
     */
    prompt(): Promise<void>;

}
