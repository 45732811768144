import React, { createContext, useEffect, useState } from 'react';
import {
  onAuthStateChanged,
  fetchSignInMethodsForEmail,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithRedirect,
  linkWithRedirect,
  signInAnonymously,
  signInWithCredential,
  OAuthProvider,
  signOut,
  User,
  getRedirectResult,
  getAuth,
  UserCredential,
  sendSignInLinkToEmail,
  EmailAuthProvider,
  linkWithCredential,
  signInWithEmailLink,
} from 'firebase/auth';
import { firebaseApp } from '../../firebase';
import { useNavigate, useLocation } from 'react-router-dom';
import { MAP_VIEW, SURFSPOT } from '../../Routes';

const auth = getAuth(firebaseApp);

export interface AuthState extends Pick<User, 'isAnonymous' | 'displayName' | 'email' | 'uid'> {
  isAdmin: boolean;
  idToken: string;
}

export interface IAuthContext {
  user: AuthState | null | undefined;
  isLoadingRedirectResult: boolean;
  isAuthenticated: boolean;
  loginWithEmailLink: (email: string) => Promise<void>;
  emailLoginLink: (email: string, returnUrl: string) => Promise<void>;
  loginWithGoogle: (returnUrl?: string) => Promise<never> | Promise<any>;
  loginWithFacebook: (returnUrl?: string) => Promise<never>;
  loginAnonymously: () => Promise<UserCredential>;
  error: string | null;
  resetError: () => void;
  logOut: () => Promise<void>;
}

export const AuthContext = createContext<IAuthContext | undefined>(undefined);

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export const { Provider } = AuthContext;

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  // undefined is before initialization
  const [authState, setAuthState] = useState<AuthState | null | undefined>(undefined);
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [isLoadingRedirectResult, setIsLoadingRedirectResult] = useState<boolean>(
    location.hash === '#redirecting'
  );
  const resetError = () => setError(null);

  useEffect(
    () =>
      onAuthStateChanged(auth, async (user: User | null) => {
        const _authState = !!user ? await addAdminClaimAndIdTokenToUser(user) : user;
        setAuthState(_authState);
      }),
    []
  );

  useEffect(() => {
    setIsLoadingRedirectResult(location.hash.includes('#redirecting'));
  }, [location]);

  // after the redirect we want to reset the hash (remove #redirecting), so that our app knows all the redirecting logic
  // has been executed, and the user has been properly loaded.
  useEffect(() => {
    getRedirectResult(auth)
      .then(() => {
        if (location.hash.includes('redirecting')) navigateToRedirectUrl();
        // this will trigger an effect that will update the isLoading variable, to indicate that everything is done
        // we work with this redirecting hash to keep loading state between redirects
        setRedirectingHash('');
      })
      .catch((error) => {
        if (error.code === 'auth/credential-already-in-use') {
          // this happens when you try to link an anonymous account with an existing google/facebook account.
          handleCredentialAlreadyInUseError(error);
        } else if (
          error.code === 'auth/account-exists-with-different-credential' ||
          error.code === 'auth/email-already-in-use'
        ) {
          handleUsersEmailAlreadyExistsError(error);
        } else {
          setErrorAndUpdateRedirectHash(error);
        }
      });
    // eslint-disable-next-line
  }, []);

  const redirectUrl = location.hash.split('redirecting&redirectUrl=')[1];

  const navigateToRedirectUrl = () => {
    if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      navigate(MAP_VIEW);
    }
  };

  const handleCredentialAlreadyInUseError = (error: any) => {
    const oauthCredential = OAuthProvider.credentialFromError(error);
    if (!!oauthCredential) {
      // set redirect to MAP
      signInWithCredential(auth, oauthCredential)
        .then(() => {
          const redirectUrlNeedsAuthorization = redirectUrl.includes(SURFSPOT);
          // since we signed in with an existing credential (in stead of linking the accounts,
          // we will not have the authorization anymore to visit the last /surfspot route
          if (redirectUrlNeedsAuthorization) {
            navigate(MAP_VIEW);
          } else {
            navigate(redirectUrl);
          }
        })
        .catch((e) => setErrorAndUpdateRedirectHash(e));
    } else {
      setErrorAndUpdateRedirectHash(error);
    }
  };

  const setErrorAndUpdateRedirectHash = (error: { code?: string; message?: string }) => {
    if (error.code === 'auth/web-storage-unsupported') {
      // https://github.com/firebase/firebase-js-sdk/issues/3004
      setError(
        'Authentication is not supported in an incognito browser, please try to log in with a browser in normal mode'
      );
    } else if (error.code === 'auth/network-request-failed') {
      setError(
        'Request failed due to lack of network connection, try again later when you have better service.'
      );
    } else {
      if (error.message) {
        setError(error.message);
      }
    }
    // this will trigger an effect that will update the isLoading variable, to indicate that everything is done
    // we work with this redirecting hash to keep loading state between redirects
    setRedirectingHash('');
  };

  const handleUsersEmailAlreadyExistsError = (error: any) => {
    console.log(JSON.stringify(error, null, 2)); // spacing level = 2
    // The provider account's email address.
    const email = error.customData.email;
    // Get the sign-in methods for this email.
    fetchSignInMethodsForEmail(auth, email)
      .then((methods) => {
        const providerId = methods[0].split('.')[0] as 'google' | 'facebook' | 'password';
        const signInMethod = providerId === 'password' ? 'email/password' : providerId;
        setErrorAndUpdateRedirectHash({
          message: `You already have an acccount with this email address through the ${signInMethod} login. Please login that way to access your data.`,
        });
      })
      .catch((e) => setErrorAndUpdateRedirectHash(e));
  };

  const setRedirectingHash = (hash: string) => {
    window.location.hash = hash;
  };

  const loginWithEmailLink = (emailForSignIn: string) => {
    // if (auth.currentUser) {
    //   const credential = EmailAuthProvider.credentialWithLink(emailForSignIn, window.location.href);

    //   // Link the credential to the current user.
    //   return linkWithCredential(auth.currentUser, credential)
    //     .then(async (usercred) => {
    //       // The provider is now successfully linked.
    //       // The anonymous user can now sign in with their email.
    //       window.localStorage.removeItem('emailForSignIn');
    //       const _authState = !!usercred.user
    //         ? await addAdminClaimAndIdTokenToUser(usercred.user)
    //         : usercred.user;
    //       setAuthState(_authState);
    //     })
    //     .catch((error) => {
    //       // Some error occurred.
    //     });
    // } else {
    // The client SDK will parse the code from the link for you.
    return signInWithEmailLink(auth, emailForSignIn, window.location.href)
      .then((result) => {
        // console.log();
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      });
    // }
  };

  const emailLoginLink = (email: string, redirectUrl: string) => {
    return sendSignInLinkToEmail(auth, email, {
      url: redirectUrl,
      handleCodeInApp: true,
    }).then(() => {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email);
      console.log('display link sent message');
    });
  };

  const loginWithGoogle = (redirectUrl?: string) =>
    authenticateWithRedirect(new GoogleAuthProvider(), redirectUrl);

  const loginWithFacebook = (redirectUrl?: string) =>
    authenticateWithRedirect(new FacebookAuthProvider(), redirectUrl);

  const authenticateWithRedirect = (
    provider: FacebookAuthProvider | GoogleAuthProvider,
    redirectUrl?: string
  ) => {
    setRedirectingHash(`redirecting${redirectUrl ? '&redirectUrl=' + redirectUrl : ''}`);
    return authState?.isAnonymous
      ? linkWithRedirect(auth.currentUser!, provider)
      : signInWithRedirect(auth, provider);
  };

  const loginAnonymously = async () => signInAnonymously(auth);

  const isAuthenticated = !!authState;

  const logOut = () => signOut(auth).catch((error) => setErrorAndUpdateRedirectHash(error));

  return (
    <Provider
      value={{
        user: authState,
        error,
        resetError,
        isLoadingRedirectResult,
        loginAnonymously,
        isAuthenticated,
        loginWithEmailLink,
        emailLoginLink: emailLoginLink,
        loginWithGoogle,
        loginWithFacebook,
        logOut,
      }}
    >
      {children}
    </Provider>
  );
};

async function addAdminClaimAndIdTokenToUser(user: User): Promise<AuthState> {
  const idTokenResult = await user.getIdTokenResult();
  return {
    ...user,
    isAdmin: !!idTokenResult.claims.admin,
    idToken: idTokenResult.token,
  };
}
