import React from 'react';
import { Button, Props } from './button';

export const PrimaryButton = React.forwardRef<HTMLButtonElement, Props>( ({ className, ...propsWithoutClassName }: Props, ref) => (
        <Button
            ref={ref}
            className={`${!!className && className}
            bg-primary-500 text-white shadow
            ${(!propsWithoutClassName.disabled || !propsWithoutClassName.isBusy) ? 'hover:bg-primary-700 hover:shadow-hover' : ''}`}
            {...propsWithoutClassName}>
        </Button>
    ));