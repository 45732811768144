import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './auth/contexts/auth.context';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { GoogleMapsProvider } from 'google-maps/contexts/google-maps.context';
import '@fontsource/roboto';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ToasterProvider } from './core/toaster/toaster.context';
const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <ToasterProvider>
      <BrowserRouter>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <GoogleMapsProvider
              mapOptions={{
                zoom: 5,
                fullscreenControl: false,
                streetViewControl: false,
                zoomControl: false,
                mapTypeControlOptions: {
                  position: 6,
                },
              }}
            >
              <App />
            </GoogleMapsProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </AuthProvider>
      </BrowserRouter>
    </ToasterProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
