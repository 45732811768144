import { BottomNavbar } from 'core/bottom-navbar/bottom-navbar';
import { PWAPrompt } from 'core/install-pwa/ios-pwa-prompt';
import { PromptInstallUpdate } from 'core/update-prompt/update-prompt';
import { AccountPage } from 'pages/account/account.page';
import { PrivacyPolicyPage } from 'pages/privacy-policy/privacy-policy.page';
import { lazy, Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { useAuth } from './auth/contexts/auth.context';
import { ErrorFallback } from './core/error-fallback';
import { LazyPreload } from './core/lazy-preload';
import { LoadingPage } from './pages/loading.page';
import {
  ACCOUNT,
  LIST_VIEW,
  LOGIN,
  NEW_SURFSPOT,
  PRIVACY_POLICY,
  SHARED_SURFSPOT,
  SURFSPOT
} from './Routes';

const SurfspotInfoPage = LazyPreload(() => import('./pages/surfspot-info.page'));
const NewSurfspotInfoPage = LazyPreload(() => import('./pages/new-surfspot-info.page'));
const SharedSurfspotPage = LazyPreload(() => import('./pages/shared-entity.page'));
const LoginPage = LazyPreload(() => import('./pages/login.page'));
const SurfMap = lazy(() => import('./pages/surfmap/surfmap.page'));
const SurfList = lazy(() => import('./pages/surfspot-list/surfspot-list.page'));

export function App() {
  const { user, loginAnonymously } = useAuth();
  const location = useLocation();
  const googleMapsHidden = location.pathname !== '/';

  useEffect(() => {
    setTimeout(() => {
      SurfspotInfoPage.preload();
      NewSurfspotInfoPage.preload();
      SharedSurfspotPage.preload();
      LoginPage.preload();
    }, 1000);
  }, []);

  useEffect(() => {
    if (user === null) {
      loginAnonymously();
    }
  }, [user, loginAnonymously]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={logError}
      onReset={() => window.location.reload()}
    >
      <div className="flex flex-col h-full overflow-x-hidden overflow-y-hidden relative">
        <div className="h-1 flex-auto overflow-y-auto">
          <Suspense fallback={<LoadingPage />}>
            <div className={googleMapsHidden ? 'w-full h-full' : ''}>
              <Routes>
                <Route path={`${LIST_VIEW}`} element={<SurfList />} />
                <Route path={`${ACCOUNT}`} element={<AccountPage />} />
                <Route path={`${SURFSPOT}/:surfspotId`} element={<SurfspotInfoPage />} />
                <Route path={NEW_SURFSPOT} element={<NewSurfspotInfoPage />} />
                <Route path={`${SHARED_SURFSPOT}`} element={<SharedSurfspotPage />} />
                <Route path={`${LOGIN}`} element={<LoginPage />} />
                <Route path={PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
              </Routes>
            </div>
          </Suspense>
          {/* separate suspense voor the surfmap, because we want to load the google maps lib 
          as soon as the App is rendered. We only want to render a loading symbol 
          when the route is actually '/' */}
          <Suspense fallback={<GoogleMapsLoadingPage />}>
            <SurfMap />
          </Suspense>
        </div>
        <BottomNavbar />
        <PWAPrompt />
        <PromptInstallUpdate />
      </div>
    </ErrorBoundary>
  );
}

const GoogleMapsLoadingPage = () => {
  return (
    <Routes>
      {/*if it's an other route, we don't want to render anything*/}
      <Route path="/*" element={null}></Route>
      <Route path="/" element={<LoadingPage />} />
    </Routes>
  );
};

const logError = (error: Error, info: { componentStack: string }) =>
  console.log(info.componentStack);
