import {useEffect, useState} from 'react';
import {useAuth} from "../../auth/contexts/auth.context";

const getInstallPromptLastSeenAt = (promptName: string): string | null => localStorage.getItem(promptName);

const setInstallPromptSeenToday = (promptName: string): void => {
    const today = new Date().getTime().toString();
    localStorage.setItem(promptName, today);
};

function getUserShouldBePromptedToInstall(promptName: string, daysToWaitBeforePromptingAgain: number): boolean {
    if (getPWADisplayMode() !== 'browser') {
        return false;
    }
    if (!getInstallPromptLastSeenAt(promptName)) {
        return true;
    } else {
        const lastPrompt = +getInstallPromptLastSeenAt(promptName)!;
        const daysSinceLastPrompt = getDaysSinceMoment(lastPrompt);
        return isNaN(daysSinceLastPrompt) || daysSinceLastPrompt > daysToWaitBeforePromptingAgain;
    }
}

//https://web.dev/customize-install/#detect-launch-type
function getPWADisplayMode() {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (document.referrer.startsWith('android-app://')) {
        return 'twa';
        // @ts-ignore
    } else if (navigator.standalone || isStandalone) {
        return 'standalone';
    }
    return 'browser';
}

export const useShouldShowPwaPrompt = (promptName: string, daysToWaitBeforePromptingAgain = 30): [boolean, () => void] => {
    const {user} = useAuth();
    const [userShouldBePromptedToInstall, setUserShouldBePromptedToInstall] = useState(
        getUserShouldBePromptedToInstall(promptName, daysToWaitBeforePromptingAgain) && !!user && !user.isAnonymous
    );

    useEffect(() => {
        if (!!user && !user.isAnonymous) {
            setTimeout(() => setUserShouldBePromptedToInstall(getUserShouldBePromptedToInstall(promptName, daysToWaitBeforePromptingAgain)), 1000)
        }
    }, [user, promptName, daysToWaitBeforePromptingAgain]);


    const handleUserSeeingInstallPrompt = () => {
        setUserShouldBePromptedToInstall(false);
        setInstallPromptSeenToday(promptName);
    };

    return [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt];
};


type msSinceEpoch = number;
const getDaysSinceMoment = (moment: msSinceEpoch): number => {
// One day Time in ms (milliseconds)
    const one_day = 1000 * 60 * 60 * 24

// To set present_dates to two variables
    const present_date = new Date();

// To Calculate the result in milliseconds and then converting into days
    return +(Math.round(present_date.getTime() - moment) / (one_day)).toFixed(0);
}
