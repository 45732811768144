export const MAP_VIEW = '/';
export const LIST_VIEW = '/list';
export const SURFSPOT = '/surfspot';
export const PRIVACY_POLICY = '/privacy-policy';
export const NEW_SURFSPOT = '/new';
export const SHARED_SURFSPOT = '/sh';
export const LOGIN = '/login';
export const ACCOUNT = '/account';
export const mapWithSelectedSurfspot = (surfspotId: string) =>
  `${MAP_VIEW}?selectedSurfspotId=${surfspotId}`;
export const getLoginWithRedirectUrl = (redirectUrl: string) =>
  `${ACCOUNT}?redirectUrl=${redirectUrl}`;
