import React from 'react';
import { Button, Props } from './button';

// we've created this component with forwardRef, so that we can pass a reference to the ReachUI modal and
// define the initial button that should be in focus
export const SecondaryButton = React.forwardRef<HTMLButtonElement, Props>( ({ className, ...propsWithoutClassName }: Props, ref) => (
    <Button
      className={`${!!className && className}
            bg-transparant text-neutral-800 border border-neutral-400 shadow
            ${
              !propsWithoutClassName.disabled || !propsWithoutClassName.isBusy
                ? 'hover:border-primary-500 hover:text-primary-500'
                : ''
            }`}
      ref={ref}
      {...propsWithoutClassName}
    ></Button>
  )
);
