import React from 'react';
import { classNames } from 'utils/class-names';
import { FormFeedback } from '../form-feedback/form-feedback';

interface Props extends React.ComponentPropsWithRef<'input'> {
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  label?: string;
  errors?: string | (string | undefined)[] | undefined;
}

export const TextInput = React.forwardRef<HTMLInputElement, Props>(
  ({ label, leadingIcon, trailingIcon, errors, ...htmlInputProps }, ref) => (
    <>
      {label && (
        <label htmlFor={htmlInputProps.id || htmlInputProps.name} className="form-label">
          {label}
        </label>
      )}
      <div className="mt-1 relative rounded-md shadow-sm">
        {leadingIcon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            {leadingIcon}
          </div>
        )}
        <input
          {...htmlInputProps}
          ref={ref}
          type={htmlInputProps.type ?? 'text'}
          id={htmlInputProps.id || htmlInputProps.name}
          className={classNames(
            leadingIcon ? 'pl-10' : '',
            trailingIcon ? 'pr-10' : '',
            htmlInputProps.disabled ? 'bg-gray-300' : '',
            errors &&
              errors.length > 0 &&
              (Array.isArray(errors) ? errors.find((curr) => curr) : true)
              ? 'form-input--error'
              : 'form-input'
          )}
        />
        {trailingIcon && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            {trailingIcon}
          </div>
        )}
      </div>
      <FormFeedback errors={errors} />
    </>
  )
);

export default TextInput;
