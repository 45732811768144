import React from 'react';

export const FormFeedback = ({
  errors,
}: {
  errors?: string | (string | undefined)[] | undefined;
}) => {
  if (errors && Array.isArray(errors)) {
    return <>{errors.map((error) => !!error && <p className="form-feedback">{error}</p>)}</>;
  } else if (errors && typeof errors === 'string') {
    return <p className="form-feedback">{errors}</p>;
  } else {
    return null;
  }
};
