import {useShouldShowPwaPrompt} from './use-should-show-pwa-prompt';

const iosInstallPromptedAt = 'iosInstallPromptedAt';

const isIOS = (): boolean => {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)

};

export const useIosInstallPrompt = (): [boolean, () => void] => {
    const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] = useShouldShowPwaPrompt(iosInstallPromptedAt);

    return [isIOS() && userShouldBePromptedToInstall, handleUserSeeingInstallPrompt];
};
