import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../auth/contexts/auth.context';
import { GlobeAsiaAustraliaIcon, QueueListIcon, UserIcon } from '@heroicons/react/24/outline';
import { HeartIcon } from '../../shared/components/icons/heart';
import { classNames } from 'utils/class-names';
import { ACCOUNT, LIST_VIEW, MAP_VIEW } from 'Routes';

export const BottomNavbar = () => {
  const { pathname } = useLocation();
  const { user, isLoadingRedirectResult } = useAuth();

  return (
    <nav style={{ height: 'var(--height-header)' }} className="bg-black px-5 flex justify-center">
      <Link
        to={MAP_VIEW}
        className={classNames(
          pathname === MAP_VIEW ? 'text-white' : 'text-gray-400 hover:text-white',
          'flex flex-col justify-center items-center space-y-1 w-full'
        )}
      >
        <GlobeAsiaAustraliaIcon className="h-[24px] w-[24px] rounded-sm inline-block" />
        <span className="text-sm">Map</span>
      </Link>

      <Link
        to={LIST_VIEW}
        className={classNames(
          pathname === LIST_VIEW ? 'text-white' : 'text-gray-400 hover:text-white',
          'flex flex-col justify-center items-center space-y-1 w-full'
        )}
      >
        <QueueListIcon className="h-[24px] w-[24px] rounded-sm inline-block" />
        <span className="text-sm">List</span>
      </Link>
      <Link
        to={ACCOUNT}
        className={classNames(
          pathname === ACCOUNT ? 'text-white' : 'text-gray-400 hover:text-white',
          'flex flex-col justify-center items-center space-y-1 w-full'
        )}
      >
        <UserIcon className="h-[24px] w-[24px] rounded-sm inline-block" />
        <span className="text-sm">Account</span>
      </Link>
      <a
        href="https://www.buymeacoffee.com/surflogs"
        className={
          'hover:text-white text-gray-400 flex flex-col justify-center items-center space-y-1 w-full'
        }
      >
        <HeartIcon className="mr-2" size={24} /> <span className="text-sm">Coffee</span>
      </a>
    </nav>
  );
};
