import React from 'react';
import { Button, Props } from './button';

export const TertiaryButton = React.forwardRef<HTMLButtonElement, Props>( ({ className, ...propsWithoutClassName }: Props, ref) => (
        <span className='-ml-4'>
        <Button
            ref={ref}
            className={`${!!className && className}
            bg-transparant text-primary-500
            ${(!propsWithoutClassName.disabled || !propsWithoutClassName.isBusy) ? 'hover:text-primary-700' : ''}`}
            {...propsWithoutClassName}>
        </Button>
        </span>
    ));
