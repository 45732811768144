import { useAuth } from 'auth/contexts/auth.context';
import { Login } from 'core/login/login';
import { LoadingPage } from 'pages/loading.page';
import React, { Suspense, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ExitIcon } from 'shared/components/icons/exit-icon';
import { TopBar } from 'shared/components/top-bar/top-bar';

const UserStats = React.lazy(() => import('./user-stats'));

export const AccountPage = () => {
  const {
    loginWithGoogle,
    loginWithFacebook,
    isLoadingRedirectResult,
    error,
    isAuthenticated,
    resetError,
    user,
    logOut,
  } = useAuth();
  // (empty deps array, else it will run the resetError function immediately, probably the resetError function reference changes)
  // eslint-disable-next-line
  useEffect(() => resetError, []);
  // A custom hook that builds on useLocation to parse
  // the query string for you.
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const returnUrl = useQuery().get('redirectUrl') || undefined;
  let displayName;
  console.log(user);
  if (!!user && user.displayName) {
    displayName = user.displayName;
  } else if (!!user && user.email) {
    displayName = user.email.split('@')[0];
  }
  return (
    <>
      <TopBar navigateBackUrl="/" title="Account" />
      <div className="padding-page">
        {isLoadingRedirectResult || !isAuthenticated ? (
          <LoadingPage />
        ) : (
          <div className="card max-w-xs m-auto mt-28" data-testid="account-page">
            <div className="mb-5">
              {!user || user.isAnonymous ? (
                <>
                  <div className="text-red-400 mb-5">{error}</div>
                  <Login
                    onClickGoogle={() => loginWithGoogle(returnUrl)}
                    onClickFacebook={() => loginWithFacebook(returnUrl)}
                  />
                </>
              ) : (
                <>
                  <p className="mb-1">Hi, {displayName}</p>

                  <button
                    type="button"
                    className="inline-flex items-center px-3 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={() => logOut()}
                  >
                    <ExitIcon size={25} className="pr-2 fill-current" />
                    Log out
                  </button>
                </>
              )}
            </div>
            <div>
              <Suspense fallback={null}>
                <h1 className="font-black text-lg mb-1">Stats</h1>
                <UserStats />
              </Suspense>
            </div>
            <div className="text-sm absolute bottom-0 right-0 mb-20 text-black flex justify-between w-full px-2">
              <Link to="/privacy-policy">Policy</Link>
              <span>v{process.env.REACT_APP_VERSION}</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
