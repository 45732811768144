import { DialogContent } from '@reach/dialog';
import { useEffect, useState } from 'react';
import { TertiaryButton } from 'shared/components/buttons/tertiary-button';
import { ServiceWorkerUpdateListener } from './ServiceWorkerUpdateListener.js';

interface Listener {
  onupdateinstalling: (e: Event) => void;
  onupdatewaiting: (e: Event) => void;
  onupdateready: () => void;
  addRegistration: (reg: ServiceWorkerRegistration | undefined) => void;
  skipWaiting: (sw: ServiceWorker | null) => void;
  removeEventListener: () => void;
}

// copied from https://dev.to/noconsulate/react-pwa-with-workbox-6dl
// which was based on https://github.com/markvanwijnen/ServiceWorkerUpdateListener
export const useServiceWorkerUpdateListener = () => {
  const [updateWaiting, setUpdateWaiting] = useState(false);
  const [registration, setRegistration] = useState<ServiceWorkerRegistration | undefined>(
    undefined
  );
  const [swListener, setSwListener] = useState<Listener | undefined>(undefined);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development' && navigator.serviceWorker.getRegistration) {
      let _listener: unknown = new ServiceWorkerUpdateListener();
      let listener = _listener as Listener;
      setSwListener(listener);
      listener.onupdateinstalling = (installingEvent: Event) => {
        console.log('SW installed', installingEvent);
      };
      listener.onupdatewaiting = (waitingEvent: Event) => {
        console.log('new update waiting', waitingEvent);
        setUpdateWaiting(true);
      };
      listener.onupdateready = () => {
        console.log('updateready event');
        window.location.reload();
      };
      navigator.serviceWorker.getRegistration().then((reg) => {
        listener.addRegistration(reg);
        setRegistration(reg);
      });

      return () => listener.removeEventListener();
    }
  }, []);
  return { updateWaiting, swListener, registration };
};

export const PromptInstallUpdate = () => {
  const { updateWaiting, swListener, registration } = useServiceWorkerUpdateListener();
  const handleUpdate = () => {
    if (swListener && registration) swListener.skipWaiting(registration.waiting);
  };
  if (!updateWaiting) return <></>;
  return (
    <DialogContent
      aria-label="update dialog"
      style={{ margin: 'auto', padding: 0, backgroundColor: 'var(--black)', width: '280px' }}
      className="rounded-md absolute bottom-20 left-0 right-0 m-auto flex flex-wrap"
    >
      <div className="flex justify-center w-full p-3 text-neutral-200 items-center">
        New update available!
        <TertiaryButton className="ml-4 -mr-4" onClick={handleUpdate}>
          Refresh
        </TertiaryButton>
      </div>
    </DialogContent>
  );
};
