import React from 'react';
import { SpinningCircle } from '../shared/components/spinning-circle/spinning-circle';

export const LoadingPage = () => {
  return (
    <div className="w-full h-full relative">
      <div className="absolute inset-0 m-auto h-14 w-14">
        <SpinningCircle className="h-full w-full text-primary-500" />
      </div>
    </div>
  );
};
