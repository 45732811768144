import React, { useEffect, useState } from 'react';

interface GoogleMapsContext {
  map: google.maps.Map | null;
  googleMapsLoaded: boolean;
  error: Error | null;
  mapContainerRef: (node: HTMLDivElement) => void;
  geocoder: google.maps.Geocoder | null;
  autocompleteService: google.maps.places.AutocompleteService | null;
}

const googleMapsContext = React.createContext<GoogleMapsContext | undefined>(undefined);

const { Provider } = googleMapsContext;

interface Props {
  children: React.ReactNode;
  mapOptions: google.maps.MapOptions;
}

const googleMapsUrl: string =
  'https://maps.google.com/maps/api/js?key=AIzaSyANtqsKOja-KVbQ-YYRyrvAOJwICJ8YTNI&libraries=places';

export const GoogleMapsProvider = React.memo(({ children, mapOptions }: Props) => {
  /** DOM container where the map canvas gets rendered. */
  const [mapContainerRefState, setMapContainerRefState] = useState<HTMLDivElement | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState<boolean>(false);
  const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null);
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null);
  const mapContainerRef = (node: HTMLDivElement) => {
    setMapContainerRefState(node);
  };

  useEffect(() => {
    if (googleMapsLoaded) {
      if (!geocoder) {
        setGeocoder(new google.maps.Geocoder());
      }
      if (!autocompleteService) {
        setAutocompleteService(new google.maps.places.AutocompleteService());
      }
    }
  }, [googleMapsLoaded, autocompleteService, geocoder]);

  useEffect(() => {
    if (googleMapsLoaded) {
      if (!!mapContainerRefState && !map) {
        // You can't unmount a google map properly, this is a known google maps bug and can creat memory leaks.
        // In this app we only want 1 map instance, so let's add some logging to make sure we only create one instance.
        const mapInstance = new google.maps.Map(mapContainerRefState, {
          ...mapOptions,
          center: mapOptions.center || { lat: 51.2154, lng: 2.9287 },
        });
        setMap(mapInstance);
      }
    }
  }, [googleMapsLoaded, mapContainerRefState, map, mapOptions]);

  useEffect(() => {
    // add google maps script after component mounted, if it's not already added
    if (!document.querySelectorAll(`[src="${googleMapsUrl}"]`).length) {
      document.body.appendChild(
        Object.assign(document.createElement('script'), {
          type: 'text/javascript',
          src: googleMapsUrl,
          onload: () => setGoogleMapsLoaded(true),
          onerror: (error: any) => setError(error),
        })
      );
    } else {
      // this block makes sure that our hook sets its variables properly if some other module already added the script.
      let timesPolled = 0;
      const pollGoogleMaps = setInterval(() => setMapLoadedIfGoogleMapsLoaded(), 500);
      const setMapLoadedIfGoogleMapsLoaded = () => {
        if (!!google) {
          setGoogleMapsLoaded(true);
          clearInterval(pollGoogleMaps);
        }
        if (timesPolled > 20) {
          clearInterval(pollGoogleMaps);
          setError(new Error('Could not load google maps after waiting 10 seconds'));
        }
        timesPolled++;
      };
    }
  }, []);

  return (
    <Provider
      value={{ map, error, mapContainerRef, googleMapsLoaded, geocoder, autocompleteService }}
    >
      {children}
    </Provider>
  );
});

export const useGoogleMaps = (): GoogleMapsContext => {
  const context = React.useContext(googleMapsContext);
  if (context === undefined) {
    throw new Error('useGoogleMap must be used within a GoogleMapsProvider');
  }
  return context;
};
