import React from 'react';

interface Props {
    icon: string,
    size?: number,
    color?: string,
    className?: string;
    viewBox?: string;
disabled?: boolean;
    [key: string]: any;
}

// tip: find icons here https://icomoon.io/app/#/select and click on generate SVG on the bottom of the page
export const Icon = ({icon, size = 16, color, disabled,className = '', viewBox = '0 0 32 32', ...restProps}: Props) => (
    <svg
        {...restProps}
        className={`${className} inline-block
                 ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
        `}
        width={`${size}px`}
        height={`${size}px`}
        viewBox={viewBox}
    >
        <path style={!!color ? {fill: color} : {}}
              d={icon}/>
    </svg>
);
