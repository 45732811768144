import React, { createContext, useEffect, useState } from 'react';
import { DialogContent } from '@reach/dialog';

interface IToasterContext {
  setError: (error: string) => void;
  setNotification: (notification: string) => void;
}

export const ToasterContext = createContext<IToasterContext | undefined>(undefined);

export function useToaster() {
  const context = React.useContext(ToasterContext);
  if (context === undefined) {
    throw new Error('useToaster must be used within a ToasterContext');
  }
  return context;
}

const { Provider } = ToasterContext;

export const ToasterProvider = ({ children }: { children: React.ReactNode }) => {
  const [error, setError] = useState<string | null>(null);
  const [notification, setNotification] = useState<string | null>(null);

  useEffect(() => {
    if (!!error) {
      setTimeout(() => setError(null), 5000);
    }
  }, [error]);

  useEffect(() => {
    if (!!notification) {
      setTimeout(() => setNotification(null), 5000);
    }
  }, [notification]);
  return (
    <Provider
      value={{
        setError,
        setNotification,
      }}
    >
      {children}
      {error && (
        <DialogContent
          aria-label="error toast"
          style={{ margin: 'auto', padding: 0, backgroundColor: 'var(--red-700)' }}
          className="rounded-md m-auto vw-90-on-phone absolute right-4 top-20 flex flex-wrap"
        >
          <div className="flex justify-center w-full p-3 text-neutral-200">{error}</div>
        </DialogContent>
      )}
      {notification && (
        <DialogContent
          aria-label="success toast"
          style={{ margin: 'auto', padding: 0, backgroundColor: 'forestgreen' }}
          className="rounded-md m-auto vw-90-on-phone absolute right-4 top-20 flex flex-wrap"
        >
          <div className="flex justify-center w-full p-3 text-neutral-200">{notification}</div>
        </DialogContent>
      )}
    </Provider>
  );
};
