import React from "react";
import {PrimaryButton} from "../shared/components/buttons";
import {FallbackProps} from "react-error-boundary";

export function ErrorFallback({error, resetErrorBoundary}: FallbackProps) {
    console.log(error);
    return (

       <div className='flex justify-center items-center w-full h-full'>
           <div role="alert" >
               <p>Something went wrong:</p>
               <pre>{error.message}</pre>
               <PrimaryButton onClick={resetErrorBoundary}>Reload page</PrimaryButton>
           </div>
        </div>
    )
}
