import React from 'react';
import { SpinningCircle } from '../spinning-circle/spinning-circle';

export interface Props extends React.ComponentPropsWithRef<'button'> {
  isBusy?: boolean;
  isLoading?: boolean;
  variant?: 'primary' | 'red';
  className?: string;
}

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  ({ isBusy, children, onClick, disabled, className, ...restProps }, ref) => {
    return (
      <button
        type="button"
        // @ts-ignore
        ref={ref}
        className={`${!!className && className} 
                focus:outline-none
                uppercase text-sm
                font-bold py-2 px-4 rounded transition ease-in-out duration-150 inline-flex
                 ${isBusy || disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={onClick}
        disabled={isBusy || disabled}
        {...restProps}
      >
        {isBusy && <SpinningCircle className="text-white mr-3" />}
        {children}
      </button>
    );
  }
);

export const ButtonBase = React.forwardRef<HTMLButtonElement, Props>(
  ({ isBusy, children, onClick, disabled, className, ...restProps }, ref) => {
    return (
      <button
        type="button"
        // @ts-ignore
        ref={ref}
        className={`${!!className && className} 
                focus:outline-none
                rounded transition ease-in-out duration-150 inline-flex
                 ${isBusy || disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={onClick}
        disabled={isBusy || disabled}
        {...restProps}
      >
        {isBusy && <SpinningCircle className="text-white mr-3" />}
        {children}
      </button>
    );
  }
);
